.hero-banner {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  background: var(--pink-yellow-gradient);

  color: var(--black);

  /* responsive */

  margin: 0 auto;
  padding: 3.2rem 6.4rem;
}

.hero-banner__logo {
  /* responsive */

  width: 12.8rem;
  height: 12.8rem;
}

.hero-banner__headline {
  letter-spacing: -1.5px;

  /* responsive */

  margin: 2.4rem 0 8px 0;

  font-size: 4.8rem;
}

.hero-banner__description {
  max-width: 58rem;

  text-align: center;

  /* responsive */

  margin-bottom: 3.2rem;

  font-size: 20px;
  line-height: 3.2rem;
}

@media only screen and (max-width: 540px) {
  .hero-banner {
    padding: 3.2rem 1.6rem;
  }

  .hero-banner__logo {
    width: 9.6rem;
    height: 9.6rem;
  }

  .hero-banner__headline {
    font-size: 3.2rem;
  }

  .hero-banner__description {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}
