.footer {
  display: flex;
  justify-content: center;
  flex-shrink: 0;

  width: 100%;

  margin-top: 2.4rem;

  background-color: var(--dark-aluminium);

  color: var(--light-aluminium);
  font-weight: 500;
  text-align: center;

  position: fixed;
  left: 0;
  bottom: 0;  
  height:40px;   /* Height of the footer */

  /* responsive */

  font-size: 1.6rem;
  line-height: 2.4rem;
}

.spacer{
  margin-bottom: 40px; /* how high is your footer */
  visibility: hidden; 
}