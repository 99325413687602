#players {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  /* margin: 0 0 15px 0; */
}

#players td,
#players th {
  border: 1px solid #ddd;
  padding: 8px;
}

#players tr:nth-child(even) {
  background-color: #f2f2f2;
}

#players tr:hover {
  background-color: #ddd;
}

#players th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #008cc1;
  color: white;
}

#players td.correct {
  background-color: #3FFF00;
}

#players td.incorrect {
  background-color: #D2042D;
  color: #fff
}

#players td {
  height: 60px;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.column {
  flex: 100%;
  margin-top: 5px;
}

.ui.menu {
  border-radius: 0;
}


i:hover {
  cursor: pointer;
}

.header-edit {
  margin: 10px 0 0 0;
  display: flex;
  justify-content: space-between;
}

.header-edit.loading {
  padding: 5px 0;
}

.header-meta {
  margin: 0 0 10px 0;
  font-weight: 500;
  font-size: 1.3rem;
}

.header-meta.loading {
  margin: 0.7rem 0 10px 0;
}

div.players {
  margin: 1rem 0 1.5rem 0;
}

div.players div.header {
  background-color: var(--dark-aluminium);
  color: #fff; 
  margin: 0.4rem 0;
  padding: 0.7rem;
  border-radius: 0.6rem;
  font-weight: 700;
  font-size: 1.15rem;
}

div.players div.header.loading {
  height: 40px;
}

div.players div.bracket {
  display: grid;
  grid-template-columns: 50% 50%;
}

div.players div.bracket div.cell {
  display: flex;
  flex-direction: column;
  background-color: rgb(239, 239, 239);
  padding: 0.9rem 0.7rem;
  border-radius: 0.6rem;
  margin: 0.4rem 0 0.4rem 0;
  justify-content: center;
  height: 60px;
}

div.players div.bracket div.cell.update-bracket {
  margin-left: 0.4rem;
  margin-right: 0.0;
}

div.players div.bracket div.cell.update-bracket:hover {
  cursor: pointer;
}

div.players div.bracket div.cell.left {
  margin-right: 0.4rem;
}

div.players div.bracket div.cell.right {
  margin-left: 0.4rem;
}

div.players div.bracket[data-round="Champion"] {
  grid-template-columns: 100%;
}

div.players div.bracket[data-round="Champion"] div.cell {
  margin-left: 0;
  margin-right: 0;
}

div.players div.bracket[data-round="Champion"][data-mode="edit"] div.cell,
div.players div.bracket[data-round="Champion"][data-mode="admin"] div.cell {
  margin-right: 0.4rem;
}

div.players div.bracket[data-mode="edit"],
div.players div.bracket[data-mode="admin"] {
  grid-template-columns: 1fr 1fr 50px;
}

div.players div.bracket[data-mode="edit"][data-round="Champion"],
div.players div.bracket[data-mode="admin"][data-round="Champion"] {
  grid-template-columns: auto 50px;
}

div.players div.bracket div.cell p {
  margin: 0.2rem 0px;
}

div.players div.bracket div.cell.correct {
  /* background-color: #3FFF00; */
  border-right: 1rem solid #3FFF00;
}

div.players div.bracket div.cell.incorrect {
  /* background-color: #D2042D; */
  border-right: 1rem solid #D2042D;
  /* color: #fff; */
}

.newTournament.input, .newTournament.button {
  margin: 0 5px 5px 0;
}

div.newTournament.dates {
  display: flex;
  margin: 0 0 5px 0;
}

.newTournament.date input {
  width: 50%;
  margin: 0 5px 0 0;
}

a.nav-bar__tab {
  color: #ffffff;
}

.events .heading {
  display: flex;
  margin: 1rem 0px 0px;
}

.events .heading .left {
  width: 140px;
  font-weight: 600;
}

.events .heading .right {
  font-weight: 600;
}

.events .event {
  margin: 1rem 0px;
    display: flex;
}

div.picks {
  margin: 1rem 0;  
  text-transform: uppercase;
  color: #fff;
  border-radius: 0.6rem;
  background-color: #474e59;
  padding: 1.3rem 0.7rem;
  display: flex;
  flex-direction: row;
}

div.picks div.text {
  font-weight: 500;
  /* border-right: 1px white solid; */
  font-size: 1.1rem;
}

div.pick-column {
  width: 50%;
}


div.picks div.score {
  font-weight: 600;
  font-size: 1.65rem;
  margin: 0.2rem 0;
}

/* @media (max-width: 1000px) {
.column{
  flex: 100%;    
  font-size: 2em;
}
h1 {
  font-size: 3em;
}
#players td {
   height: 80px;
}
p { font-size: 1.5em; }

} */