@import url(https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;600&family=Inter:wght@400;500;600;700&family=Space+Grotesk:wght@400;500;600&display=swap);
/* Theme */

:root {
  --orange: #ff4f40;
  --indigo: #635dff;
  --white: #fff;
  --light-aluminium: #eaecee;
  --aluminium: #bdc4cf;
  --dark-aluminium: #2a2e35;
  --black: #000000;
  --yellow: #ebca40;
  --mandarine: #ff7f38;
  --pink: #ff44dd;
  --blue: #3885ff;
  --aqua: #3ec6eb;
  --emerald: #1bc99f;

  --yellow-mandarine-gradient: linear-gradient(
    153.07deg,
    var(--yellow) -2.47%,
    var(--mandarine) 102.78%
  );

  --mandarine-orange-gradient: linear-gradient(
    153.07deg,
    var(--mandarine) -2.47%,
    var(--orange) 102.78%
  );

  --pink-yellow-gradient: linear-gradient(
    153.07deg,
    var(--pink) -2.47%,
    var(--yellow) 102.78%
  );

  --pink-indigo-gradient: linear-gradient(
    153.07deg,
    var(--pink) -2.47%,
    var(--indigo) 102.78%
  );

  --indigo-aqua-gradient: linear-gradient(
    153.07deg,
    var(--indigo) -2.47%,
    var(--aqua) 102.78%
  );

  --blue-aqua-gradient: linear-gradient(
    153.07deg,
    var(--blue) -2.47%,
    var(--aqua) 102.78%
  );

  --aqua-emerald-gradient: linear-gradient(
    153.07deg,
    var(--aqua) -2.47%,
    var(--emerald) 102.78%
  );

  --emerald-yellow-gradient: linear-gradient(
    153.07deg,
    var(--emerald) -2.47%,
    var(--yellow) 102.78%
  );

  --font-primary: "Inter", sans-serif;
  --font-secondary: "Space Grotesk", sans-serif;
  --font-mono: "Fira Code", monospace;
}

.auth0-features {
  /* responsive */

  padding: 3.2rem 6.4rem;
}

.auth0-features__title {
  color: var(--white);
  font-weight: 600;
  text-align: center;

  /* responsive */

  margin-top: 0;
  margin-bottom: 4.8rem;

  font-size: 3.2rem;
  line-height: 3.2rem;
}

.auth0-features__grid {
  display: grid;

  /* responsive */

  grid-template-columns: 1fr 1fr;
  grid-column-gap: 6.4rem;
  column-gap: 6.4rem;
  grid-row-gap: 6.4rem;
  row-gap: 6.4rem;
}

.auth0-feature {
  background-color: var(--white);

  color: var(--black);

  cursor: pointer;

  transition: all 0.3s ease-in-out 0s;

  /* responsive */

  padding: 4.8rem;

  border-radius: 1.6rem;
}

.auth0-feature:hover {
  transform: scale(1.03);
}

.auth0-feature__headline {
  display: flex;
  align-items: center;

  margin-top: 0;

  color: var(--black);
  font-weight: 600;
  letter-spacing: -0.05rem;

  /* responsive */

  font-size: 2.4rem;
  line-height: 3.2rem;
}

.auth0-feature__icon {
  /* responsive */

  margin-right: 1.6rem;
}

.auth0-feature:hover .auth0-feature__headline {
  -webkit-text-decoration: underline solid 2px var(--black);
          text-decoration: underline solid 2px var(--black);
  text-underline-offset: 2px;

  transition: all 0.3s ease-in-out 0s;
}

.auth0-feature__description {
  margin: 0;

  color: rgb(36, 36, 36);
  font-weight: 500;

  opacity: 0.7;

  /* responsive */

  font-size: 1.6rem;
  line-height: 2.4rem;
}

@media only screen and (max-width: 900px) {
  .auth0-features__grid {
    grid-template-columns: 1fr;
    grid-row-gap: 3.2rem;
    row-gap: 3.2rem;
  }

  .auth0-feature {
    padding: 3.2rem;
    border-radius: 0.8rem;
  }

  .auth0-feature__headline {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .auth0-feature__icon {
    height: 3.6rem;

    margin-right: 1.6rem;
  }

  .auth0-feature__description {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
}

@media only screen and (max-width: 480px) {
  .auth0-features {
    padding: 1.6rem;
  }

  .auth0-features__title {
    font-size: 2rem;
    line-height: 2.8rem;
    margin-bottom: 2.4rem;
  }

  .auth0-features__grid {
    grid-template-columns: 1fr;
    grid-row-gap: 1.6rem;
    row-gap: 1.6rem;
  }

  .auth0-feature {
    padding: 3.2rem;
    border-radius: 0.8rem;
  }

  .auth0-feature__headline {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .auth0-feature__icon {
    height: 3.6rem;

    margin-right: 1.6rem;
  }

  .auth0-feature__description {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
}

.button {
  border: 0;
  border-radius: 0.4rem;

  font-family: var(--font-primary);
  font-weight: 600;
  color: var(--white);

  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;

  transition: background 0.3s ease-out, color 0.3s ease-out;

  /* responsive */

  min-width: 10rem;
  padding: 1.6rem 1.6rem;

  font-size: 1.6rem;
  line-height: 2.4rem;
}

.button--compact {
  /* responsive */

  padding: 1rem 1.6rem;

  font-size: 1.6rem;
  line-height: 1.6rem;
}

.button--primary {
  background-color: var(--indigo);
}

.button--primary:hover {
  background: rgba(99, 93, 255, 0.85);
}

.button--secondary {
  background-color: var(--white);
  color: var(--black);
}

.button--secondary:hover {
  background: rgba(255, 255, 255, 0.85);
}

@media only screen and (max-width: 480px) {
  .button {
    /* responsive */

    min-width: 0.8rem;
    padding: 1rem 1.2rem;

    font-size: 1.3rem;
    line-height: 2.2rem;
  }

  .button--compact {
    /* responsive */

    padding: 0.6rem 1rem;

    font-size: 1.3rem;
    line-height: 2rem;
  }
}

.code-snippet {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0.8rem;
  overflow: hidden;
}

.code-snippet__title {
  height: 4.8rem;
  width: 100%;

  background-color: rgb(189, 196, 207);
  color: var(--black);

  font-family: "Fira Code", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
  font-weight: 600;

  /*  responsive */

  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 1.2rem 1.6rem;
}

.code-snippet__container {
  background-color: var(--dark-aluminium);

  overflow-x: auto;
}

.code-snippet__wrapper {
  display: inline-block;
  padding: 32px;
  min-height: 18rem;
}

.code-snippet__body {
  margin: 0;

  color: var(--white);
  font-size: 16px;
  line-height: 32px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

@media only screen and (max-width: 480px) {
  .code-snippet__title {
    /*  responsive */

    height: 4.4rem;

    font-size: 1.4rem;
    line-height: 2.2rem;
    padding: 1.2rem 1.6rem;
  }

  .code-snippet__wrapper {
    padding: 1.6rem;
  }

  .code-snippet__body {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}

.footer {
  display: flex;
  justify-content: center;
  flex-shrink: 0;

  width: 100%;

  margin-top: 2.4rem;

  background-color: var(--dark-aluminium);

  color: var(--light-aluminium);
  font-weight: 500;
  text-align: center;

  position: fixed;
  left: 0;
  bottom: 0;  
  height:40px;   /* Height of the footer */

  /* responsive */

  font-size: 1.6rem;
  line-height: 2.4rem;
}

.spacer{
  margin-bottom: 40px; /* how high is your footer */
  visibility: hidden; 
}
.hero-banner {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  background: var(--pink-yellow-gradient);

  color: var(--black);

  /* responsive */

  margin: 0 auto;
  padding: 3.2rem 6.4rem;
}

.hero-banner__logo {
  /* responsive */

  width: 12.8rem;
  height: 12.8rem;
}

.hero-banner__headline {
  letter-spacing: -1.5px;

  /* responsive */

  margin: 2.4rem 0 8px 0;

  font-size: 4.8rem;
}

.hero-banner__description {
  max-width: 58rem;

  text-align: center;

  /* responsive */

  margin-bottom: 3.2rem;

  font-size: 20px;
  line-height: 3.2rem;
}

@media only screen and (max-width: 540px) {
  .hero-banner {
    padding: 3.2rem 1.6rem;
  }

  .hero-banner__logo {
    width: 9.6rem;
    height: 9.6rem;
  }

  .hero-banner__headline {
    font-size: 3.2rem;
  }

  .hero-banner__description {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

.loader {
  height: 5rem;
  width: 5rem;

  margin: auto;

  animation: spin 2s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-bar__container {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;

  box-shadow: rgb(90 95 102) 0-1.5px 0 inset;

  z-index: 300;
  background-color: var(--dark-aluminium);
}

.nav-bar {
  flex: 1 1;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  /* responsive */

  height: 80px;
  max-width: 1200px;

  padding: 0 32px 0 24px;
  margin: 0;
}

.nav-bar__brand {
  display: flex;
  align-items: center;

  height: 100%;

  /* responsive */

  margin-right: 64px;
}

.nav-bar__link {
  display: flex;
  align-items: center;

  height: 100%;
}

.nav-bar__logo {
  height: 50%;
}

.nav-bar__tabs {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav-bar__tab {
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-right: 24px;

  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

.nav-bar__tab:last-child {
  margin-right: 0;
}

.nav-bar__tab--active {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
  -webkit-text-decoration-color: var(--white);
          text-decoration-color: var(--white);

  /* responsive */

  text-decoration-thickness: 2px;
  text-underline-offset: 8px;
}

.nav-bar__tab:hover {
  color: var(--white);
}

.nav-bar__actions {
  margin-left: 24px;
}

@media only screen and (max-width: 480px) {
  .nav-bar__tab {
    font-size: 1.2rem;
  }

  .nav-bar {
    height: 4.5rem;

    padding: 0.8rem 1.6rem;
  }

  .nav-bar__brand {
    display: flex;
    align-items: center;

    margin-right: 1.6rem;
  }

  /* .nav-bar__logo {
    height: 2rem;
  } */

  .nav-bar__tab--active {
    text-decoration-thickness: 2px;
    text-underline-offset: 6px;
  }

  .nav-bar__actions {
    margin-left: 24px;
  }
}

@media only screen and (max-width: 340px) {
  .nav-bar__tab {
    font-size: 1.3rem;
  }

  .nav-bar {
    height: 6.4rem;

    padding: 0.8rem 1.6rem;
  }

  .nav-bar__brand {
    display: flex;
    align-items: center;

    margin-right: 1.6rem;
  }

  .nav-bar__logo {
    height: 2.8rem;
  }

  .nav-bar__tab--active {
    text-decoration-thickness: 2px;
    text-underline-offset: 6px;
  }

  .nav-bar__actions {
    margin-left: 24px;
  }
}

.messages-grid {
  display: flex;
  flex-direction: column;
}

.messages-grid__header {
  margin: 48px 0 0 0;

  color: var(--white);
  font-weight: 500;
  text-align: center;

  /* responsive */

  font-size: 24px;
  line-height: 32px;
}

.messages-grid__options {
  display: flex;

  /* responsive */

  border-radius: 16px;
  margin: 32px auto;
}

.messages-grid__option {
  border: 2px solid rgb(96, 96, 96);
  border-right: 0;
  background-color: var(--black);

  color: var(--white);
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;

  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none;

  transition: background 0.3s ease-out, color 0.3s ease-out;

  /* responsive */

  padding: 8px 24px;
  margin: 0;

  font-size: 16px;
  line-height: 24px;
}

.messages-grid__option:first-child {
  border-radius: 8px 0 0 8px;
}

.messages-grid__option:last-child {
  border-radius: 0 8px 8px 0;
  border-right: 2px solid rgb(96, 96, 96);
}

.messages-grid__option--active,
.messages-grid__option--active:hover {
  background-color: var(--white);
  color: var(--black);
}

@media only screen and (max-width: 540px) {
  .messages-grid__header {
    margin: 1.6rem 0 1.6rem 0;

    font-size: 2rem;
    line-height: 2.8rem;
  }

  .messages-grid__options {
    max-width: 24rem;
    flex-wrap: wrap;
    border-radius: 0.8rem;
    margin: 0.8rem auto 1.6rem;
  }

  .messages-grid__option {
    width: 12rem;
    padding: 0.4rem 1.6rem;

    border: 0.2rem solid rgb(96, 96, 96);

    font-size: 1.4rem;
  }

  .messages-grid__option:nth-child(1) {
    border-bottom-left-radius: 0;
    border-right: 0;
    border-bottom: 0;
  }

  .messages-grid__option:nth-child(2) {
    border-top-right-radius: 0.8rem;
    border-bottom: 0;
  }

  .messages-grid__option:nth-child(3) {
    border-bottom-left-radius: 0.8rem;
    border-right: 0;
  }

  .messages-grid__option:nth-child(4) {
    border-top-right-radius: 0;
  }
}

.profile-grid {
  display: flex;
  flex-direction: column;

  /* responsive */

  margin-top: 48px;
}

.profile__header {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 16px;
  column-gap: 16px;
}

.profile__avatar {
  border: solid 2px rgb(62, 198, 235);
  border-radius: 50%;

  /* responsive */

  height: 80px;
  width: 80px;
}

.profile__headline {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.profile__title {
  margin-top: 0;
  margin-bottom: 0;

  color: var(--white);
}

.profile__details {
  /* responsive */

  margin-top: 32px;
}

@media only screen and (max-width: 480px) {
  .profile-grid {
    margin-top: 2.4rem;
  }

  .profile__avatar {
    height: 5.6rem;
    width: 5.6rem;

    border-radius: 50%;

    border: solid 0.13rem rgb(62, 198, 235);
  }

  .profile__title {
    font-size: 1.6rem;
  }

  .profile__description {
    font-size: 1.3rem;
  }

  .profile__details {
    margin-top: 1.6rem;
  }
}



.page-layout {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  width: 100%;
}

.page-layout__content {
  flex: 1 1;
  flex-basis: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  max-width: 120rem;
  width: 100%;
}

.content-layout {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  width: 100%;
  min-height: 480px;

  /* responsive */

  padding: 48px;
}

.content__title {
  margin-top: 0;

  color: var(--white);
}

.content__body {
  /* responsive */

  font-size: 1.6rem;
  line-height: 2.4rem;
}

@media only screen and (max-width: 480px) {
  .content-layout {
    padding: 1.6rem;
  }

  .content__title {
    font-size: 2.4rem;
  }
}





#players {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  /* margin: 0 0 15px 0; */
}

#players td,
#players th {
  border: 1px solid #ddd;
  padding: 8px;
}

#players tr:nth-child(even) {
  background-color: #f2f2f2;
}

#players tr:hover {
  background-color: #ddd;
}

#players th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #008cc1;
  color: white;
}

#players td.correct {
  background-color: #3FFF00;
}

#players td.incorrect {
  background-color: #D2042D;
  color: #fff
}

#players td {
  height: 60px;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.column {
  flex: 100% 1;
  margin-top: 5px;
}

.ui.menu {
  border-radius: 0;
}


i:hover {
  cursor: pointer;
}

.header-edit {
  margin: 10px 0 0 0;
  display: flex;
  justify-content: space-between;
}

.header-edit.loading {
  padding: 5px 0;
}

.header-meta {
  margin: 0 0 10px 0;
  font-weight: 500;
  font-size: 1.3rem;
}

.header-meta.loading {
  margin: 0.7rem 0 10px 0;
}

div.players {
  margin: 1rem 0 1.5rem 0;
}

div.players div.header {
  background-color: var(--dark-aluminium);
  color: #fff; 
  margin: 0.4rem 0;
  padding: 0.7rem;
  border-radius: 0.6rem;
  font-weight: 700;
  font-size: 1.15rem;
}

div.players div.header.loading {
  height: 40px;
}

div.players div.bracket {
  display: grid;
  grid-template-columns: 50% 50%;
}

div.players div.bracket div.cell {
  display: flex;
  flex-direction: column;
  background-color: rgb(239, 239, 239);
  padding: 0.9rem 0.7rem;
  border-radius: 0.6rem;
  margin: 0.4rem 0 0.4rem 0;
  justify-content: center;
  height: 60px;
}

div.players div.bracket div.cell.update-bracket {
  margin-left: 0.4rem;
  margin-right: 0.0;
}

div.players div.bracket div.cell.update-bracket:hover {
  cursor: pointer;
}

div.players div.bracket div.cell.left {
  margin-right: 0.4rem;
}

div.players div.bracket div.cell.right {
  margin-left: 0.4rem;
}

div.players div.bracket[data-round="Champion"] {
  grid-template-columns: 100%;
}

div.players div.bracket[data-round="Champion"] div.cell {
  margin-left: 0;
  margin-right: 0;
}

div.players div.bracket[data-round="Champion"][data-mode="edit"] div.cell,
div.players div.bracket[data-round="Champion"][data-mode="admin"] div.cell {
  margin-right: 0.4rem;
}

div.players div.bracket[data-mode="edit"],
div.players div.bracket[data-mode="admin"] {
  grid-template-columns: 1fr 1fr 50px;
}

div.players div.bracket[data-mode="edit"][data-round="Champion"],
div.players div.bracket[data-mode="admin"][data-round="Champion"] {
  grid-template-columns: auto 50px;
}

div.players div.bracket div.cell p {
  margin: 0.2rem 0px;
}

div.players div.bracket div.cell.correct {
  /* background-color: #3FFF00; */
  border-right: 1rem solid #3FFF00;
}

div.players div.bracket div.cell.incorrect {
  /* background-color: #D2042D; */
  border-right: 1rem solid #D2042D;
  /* color: #fff; */
}

.newTournament.input, .newTournament.button {
  margin: 0 5px 5px 0;
}

div.newTournament.dates {
  display: flex;
  margin: 0 0 5px 0;
}

.newTournament.date input {
  width: 50%;
  margin: 0 5px 0 0;
}

a.nav-bar__tab {
  color: #ffffff;
}

.events .heading {
  display: flex;
  margin: 1rem 0px 0px;
}

.events .heading .left {
  width: 140px;
  font-weight: 600;
}

.events .heading .right {
  font-weight: 600;
}

.events .event {
  margin: 1rem 0px;
    display: flex;
}

div.picks {
  margin: 1rem 0;  
  text-transform: uppercase;
  color: #fff;
  border-radius: 0.6rem;
  background-color: #474e59;
  padding: 1.3rem 0.7rem;
  display: flex;
  flex-direction: row;
}

div.picks div.text {
  font-weight: 500;
  /* border-right: 1px white solid; */
  font-size: 1.1rem;
}

div.pick-column {
  width: 50%;
}


div.picks div.score {
  font-weight: 600;
  font-size: 1.65rem;
  margin: 0.2rem 0;
}

/* @media (max-width: 1000px) {
.column{
  flex: 100%;    
  font-size: 2em;
}
h1 {
  font-size: 3em;
}
#players td {
   height: 80px;
}
p { font-size: 1.5em; }

} */
/* @import "general.css"; */

